@import "~@serverready-ui/design-language/dist/sass/all";

.sidebarLink {
  width: 100%;
  display: block;
  padding: $spacing-xxs $ui-spacing-xs;

  &:global(.active) {
    background-color: $brand-light-dp8;
  }

  &:hover {
    background-color: $brand-light-dp24;
  }
}
