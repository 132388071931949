// This file is generated by bin/makeColorsCSS.mjs. Do not edit directly.
// Last updated: 2023-05-01T07:44:41.943Z
$brand-900: #1e277a;
$brand: #2b3990;
$brand-700: #33429c;
$brand-600: #3c4ca8;
$brand-500: #4254b2;
$brand-400: #5e6ebd;
$brand-300: #7b88c9;
$brand-200: #a0aad8;
$brand-100: #c5cbe8;
$brand-50: #e8eaf6;
$brand-light-900: #2d2dcc;
$brand-light-800: #1c54ec;
$brand-light: #0367ff;
$brand-light-600: #007cff;
$brand-light-500: #008bff;
$brand-light-400: #009dff;
$brand-light-300: #46afff;
$brand-light-200: #85c6ff;
$brand-light-100: #b8dcff;
$brand-light-50: #e1f1ff;
$orange: #ff9a03;
$orange-faded: #fff0da;
$purple: #9a03ff;
$purple-faded: #f0daff;
$pink: #ff0368;
$pink-faded: #ffdae9;
$error: #B00020;
$error-tonal: #ed323b;
$error-faded: #f4d9de;
$error-dark: #d0667a;
$warning: #FBA755;
$warning-tonal: #fcb76d;
$warning-faded: #fff2e6;
$success: #32BB8A;
$success-tonal: #60c79f;
$success-faded: #e1f5ee;
$black: #000;
$surface-black: #121212;
$surface-black-light: #1a1a1a;
$surface-black-lighter: #2a2a2a;
$surface-black-brand-mix-dp1: #2a388a;
$surface-black-brand-mix-dp2: #2a3788;
$surface-black-brand-mix-dp3: #293686;
$surface-black-brand-mix-dp4: #293685;
$surface-black-brand-mix-dp6: #293583;
$surface-black-brand-mix-dp8: #283581;
$surface-black-brand-mix-dp12: #28347f;
$surface-black-brand-mix-dp16: #28347e;
$surface-black-brand-mix-dp24: #27337c;
$surface-black-brand-mix-dp26: #27337b;
$surface-black-brand-mix-dp28: #27327a;
$surface-black-brand-mix-dp32: #273279;
$surface-black-brand-mix-dp40: #263277;
$surface-black-brand-mix-dp48: #263176;
$surface-black-brand-mix-dp52: #181b2d;
$surface-black-brand-mix-dp60: #171a2c;
$surface-black-brand-mix-dp68: #171a2a;
$surface-black-brand-mix-dp72: #171a29;
$surface-black-brand-mix-dp74: #171928;
$surface-black-brand-mix-dp76: #161927;
$surface-black-brand-mix-dp84: #161825;
$surface-black-brand-mix-dp88: #161824;
$surface-black-brand-mix-dp92: #151722;
$surface-black-brand-mix-dp94: #151720;
$surface-black-brand-mix-dp96: #15161e;
$surface-black-brand-mix-dp97: #14161d;
$surface-black-brand-mix-dp98: #14151b;
$surface-black-brand-mix-dp99: #141419;
$black-brand-light-mix-dp1: #0362f3;
$black-brand-light-mix-dp2: #0360ee;
$black-brand-light-mix-dp3: #035feb;
$black-brand-light-mix-dp4: #035ee9;
$black-brand-light-mix-dp6: #035ce3;
$black-brand-light-mix-dp8: #035be1;
$black-brand-light-mix-dp12: #0359dc;
$black-brand-light-mix-dp16: #0358d9;
$black-brand-light-mix-dp24: #0357d7;
$black-brand-light-mix-dp26: #0356d4;
$black-brand-light-mix-dp28: #0355d2;
$black-brand-light-mix-dp32: #0354cf;
$black-brand-light-mix-dp40: #0353cc;
$black-brand-light-mix-dp48: #0352ca;
$black-brand-light-300-mix-dp1: #43a7f3;
$black-brand-light-300-mix-dp2: #42a3ee;
$black-brand-light-300-mix-dp3: #41a1eb;
$black-brand-light-300-mix-dp4: #40a0e9;
$black-brand-light-300-mix-dp6: #3f9ce3;
$black-brand-light-300-mix-dp8: #3e9ae1;
$black-brand-light-300-mix-dp12: #3d97dc;
$black-brand-light-300-mix-dp16: #3c95d9;
$black-brand-light-300-mix-dp24: #3b93d7;
$black-brand-light-300-mix-dp26: #3b92d4;
$black-brand-light-300-mix-dp28: #3a90d2;
$black-brand-light-300-mix-dp32: #398ecf;
$black-brand-light-300-mix-dp40: #388ccc;
$black-brand-light-300-mix-dp48: #388bca;
$surface-black-dp1: #1212120d;
$surface-black-dp2: #12121212;
$surface-black-dp3: #12121214;
$surface-black-dp4: #12121217;
$surface-black-dp6: #1212121c;
$surface-black-dp8: #1212121f;
$surface-black-dp12: #12121224;
$surface-black-dp16: #12121226;
$surface-black-dp24: #12121229;
$surface-black-dp26: #1212122b;
$surface-black-dp28: #1212122e;
$surface-black-dp32: #12121230;
$surface-black-dp40: #12121233;
$surface-black-dp48: #12121236;
$brand-dp1: #2b39900d;
$brand-dp2: #2b399012;
$brand-dp3: #2b399014;
$brand-dp4: #2b399017;
$brand-dp6: #2b39901c;
$brand-dp8: #2b39901f;
$brand-dp12: #2b399024;
$brand-dp16: #2b399026;
$brand-dp24: #2b399029;
$brand-dp26: #2b39902b;
$brand-dp28: #2b39902e;
$brand-dp32: #2b399030;
$brand-dp40: #2b399033;
$brand-dp48: #2b399036;
$brand-light-dp1: #0367ff0d;
$brand-light-dp2: #0367ff12;
$brand-light-dp3: #0367ff14;
$brand-light-dp4: #0367ff17;
$brand-light-dp6: #0367ff1c;
$brand-light-dp8: #0367ff1f;
$brand-light-dp12: #0367ff24;
$brand-light-dp16: #0367ff26;
$brand-light-dp24: #0367ff29;
$brand-light-dp26: #0367ff2b;
$brand-light-dp28: #0367ff2e;
$brand-light-dp32: #0367ff30;
$brand-light-dp40: #0367ff33;
$brand-light-dp48: #0367ff36;
$on-light-surface: #1C1B1F;
$grey-medium-light: #bebbbe;
$neutral: #fff;
$neutral-90: #e6e6e6;
$neutral-60: #999999;
$neutral-45: #737373;
$neutral-30: #4d4d4d;
$neutral-90-transparent: #ffffff1a;
$neutral-60-transparent: #ffffff66;
$neutral-45-transparent: #ffffff8c;
$neutral-30-transparent: #ffffffb3;
