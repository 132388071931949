@use "sass:math";

@import "@serverready-ui/design-language/dist/sass/all";

.sr-text {
  display: block;
}

.italic {
  font-style: $font-italic;
}

// Font sizes
.sizeTitle {
  font-size: $font-size-title;

  line-height: $line-height-title;
  letter-spacing: -0.01em;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-mobile-title;
    line-height: $line-height-mobile-title;
  }
}

.sizeH1 {
  font-size: $font-size-jm;
  line-height: $line-height-jm;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-mobile-jm;
    line-height: $line-height-mobile-jm;
  }
}

.sizeH2 {
  font-size: $font-size-xl;
  line-height: $line-height-xl;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-mobile-xl;
    line-height: $line-height-mobile-xl;
  }
}

.sizeH3 {
  font-size: $font-size-lg;
  line-height: $line-height-lg;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-mobile-lg;
    line-height: $line-height-mobile-lg;
  }
}

.sizeLg {
  font-size: $font-size-lg;
  line-height: $line-height-lg;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-mobile-lg;
    line-height: $line-height-mobile-lg;
  }
}

.sizeMd {
  font-size: $font-size-md;
  line-height: $line-height-md;
}

.sizeSm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

.sizeXs {
  font-size: $font-size-xs;
  line-height: $line-height-xs;
}

.sizeInherit {
  font-size: inherit;
}

.alignLeftSm {
  @include media-breakpoint-down(sm) {
    text-align: left !important;
  }
}

.alignCenterSm {
  @include media-breakpoint-down(sm) {
    text-align: center !important;
  }
}

.alignRightSm {
  @include media-breakpoint-down(sm) {
    text-align: right !important;
  }
}

// Font weights
.weightInherit {
  font-family: inherit;
}

.weightLight {
  font-family: $font-light;

  &.italic {
    font-family: $font-light-italic;
  }
}

.weightRegular {
  font-family: $font-regular;

  &.italic {
    font-family: $font-italic;
  }
}

.weightSemibold {
  font-family: $font-semibold;

  &.italic {
    font-family: $font-semibold-italic;
  }
}

// Align
.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.inline {
  display: inline;
}

.transformUppercase {
  text-transform: uppercase;
}

.transformLowercase {
  text-transform: lowercase;
}

.transformCapitalize {
  text-transform: capitalize;
}

.forceSingleLine {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
