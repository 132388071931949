/**
 * This is the root file of all Sass variables, mixins and functions.
 * Only load and define functions, mixins and variables in this file and child files.
 * This file will be loaded from every component, so outputting CSS from this file would
 * result in duplicated data.
 */

@import "~normalize-scss/sass/normalize";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "colors";
@import "variables";
@import "layers";
@import "functions";
@import "spacing-ui";
@import "type-body";
@import "mixins";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $brand-400 !important;
}
