@import "~@serverready-ui/design-language/dist/sass/all";

.alignCenter {
  display: block;
  margin: 0 auto;
}

.displayBlock {
  display: block;
}

.clickable {
  cursor: pointer;
}

.rounded {
  border-radius: $border-radius-large;
}
