@import "@serverready-ui/design-language/dist/sass/all";

.srColumn {
  &.noGutters {
    padding-left: 0;
    padding-right: 0;
  }
}


.dividerLeft {
  border-left: 1px solid $neutral-90;
}

.dividerRight {
  border-right: 1px solid $neutral-90;
}

.alignTextLeft {
  text-align: left;
}

.alignTextCenter {
  text-align: center;
}

.alignTextRight {
  text-align: right;
}

.hasTextOverflow {
  min-width: 0;
}
