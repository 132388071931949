@import "~@serverready-ui/design-language/dist/sass/all";

.checkbox {
  cursor: pointer;
  color: white;

  input {
    // we want to hide the input without having to use display: none so focusing works
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    // left -100vw is here in case the other methods don't properly hide the input depending on the browser
    left: -100vw;
    opacity: 0;

    &:focus + span {
      box-shadow: 0 0 6px 0 $brand-light-300;
    }
  }
}

.disabled {
  .labelText {
    color: $grey-medium-light;
    pointer-events: none;
  }
}

.labelText {
  a {
    text-decoration: underline;
  }
}
