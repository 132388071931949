@use "sass:math";

// Font size
$font-size-title: 48px;
$font-size-jm: 32px;
$font-size-xl: 24px;
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-tn: 10px; // deprecated

// Font size - mobile headings
$font-size-mobile-title: 32px;
$font-size-mobile-jm: 24px;
$font-size-mobile-xl: 20px;
$font-size-mobile-lg: 18px;

// Line height
$line-height-title: 1.1;
$line-height-jm: 1.16;
$line-height-xl: 1.4;
$line-height-lg: 1.3;
$line-height-md: 1.6;
$line-height-sm: 1.5;
$line-height-xs: 1.57;
$line-height-tn: 1.4; // deprecated

// Line height - mobile headings
$line-height-mobile-title: 1.35;
$line-height-mobile-jm: 1.2;
$line-height-mobile-xl: 1.36;
$line-height-mobile-lg: 1.3;
