@import "@serverready-ui/design-language/dist/sass/all";

$spacing-breakpoint: "sm";
$properties: "padding", "margin";
$breakpoints: sm, md, lg, xl;
$sizes: none, tn, xs, sm, md, lg, xl, "2x", jm;
$places: top, left, right, bottom;
$values: (
  "none": 0,
  "tn": $ui-spacing-tn,
  "xs": $ui-spacing-xs,
  "sm": $ui-spacing-sm,
  "md": $ui-spacing-md,
  "lg": $ui-spacing-lg,
  "xl": $ui-spacing-xl,
  "2x": $ui-spacing-2xl,
  "jm": $ui-spacing-jm,
);

$mobile: (
  "jm": $responsive-spacing-jm,
  "2x": $responsive-spacing-2xl,
  "xl": $responsive-spacing-xl,
  "lg": $responsive-spacing-lg,
  "md": $responsive-spacing-md,
);

.spacing {
  box-sizing: border-box;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

// iterate multiple times to ensure importance
// handle both margin and padding
@each $property in $properties {
  @each $size in $sizes {
    // abstract directions (horizontal, vertical, global)
    .#{$property}-#{$size} {
      #{$property}: map-get($values, $size);

      @if (map-get($mobile, $size)) {
        @include media-breakpoint-down($spacing-breakpoint) {
          #{$property}: map-get($mobile, $size);
        }
      }
    }

    .#{$property}-horizontal-#{$size} {
      #{$property}-left: map-get($values, $size);
      #{$property}-right: map-get($values, $size);

      @if (map-get($mobile, $size)) {
        @include media-breakpoint-down($spacing-breakpoint) {
          #{$property}-left: map-get($mobile, $size);
          #{$property}-right: map-get($mobile, $size);
        }
      }
    }

    .#{$property}-vertical-#{$size} {
      #{$property}-top: map-get($values, $size);
      #{$property}-bottom: map-get($values, $size);

      @if (map-get($mobile, $size)) {
        @include media-breakpoint-down($spacing-breakpoint) {
          #{$property}-top: map-get($mobile, $size);
          #{$property}-bottom: map-get($mobile, $size);
        }
      }
    }
  }

  // loop twice to ensure importance order is persistent
  // we want the order of importance to be based on specificity, eg:
  // global < horizontal/vertical < top/left/right/bottom
  @each $size in $sizes {
    @each $place in $places {
      .#{$property}-#{$place}-#{$size} {
        #{$property}-#{$place}: map-get($values, $size);

        @if (map-get($mobile, $size)) {
          @include media-breakpoint-down($spacing-breakpoint) {
            #{$property}-#{$place}: map-get($mobile, $size);
          }
        }
      }
    }
  }

  // responsive
  // since we're doing a mobile-first approach like Bootstrap does
  // responsive styles are the most important. therefore we want to
  // loop over these last
  @each $breakpoint in $breakpoints {
    @each $size in $sizes {
      @include media-breakpoint-up($breakpoint) {
        .#{$breakpoint}-#{$property}-#{$size} {
          #{$property}: map-get($values, $size);
        }

        .#{$breakpoint}-#{$property}-horizontal-#{$size} {
          #{$property}-left: map-get($values, $size);
          #{$property}-right: map-get($values, $size);
        }

        .#{$breakpoint}-#{$property}-vertical-#{$size} {
          #{$property}-top: map-get($values, $size);
          #{$property}-bottom: map-get($values, $size);
        }

        @each $place in $places {
          .#{$breakpoint}-#{$property}-#{$place}-#{$size} {
            #{$property}-#{$place}: map-get($values, $size);
          }
        }
      }
    }
  }
}

// we use this class to "hide" an element while still having it:
// - available in the DOM
// - focusable (using visibility: hidden or display: none means the element is not focusable)
.invisible {
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
}
