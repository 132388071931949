@import "~@serverready-ui/design-language/dist/sass/all";

$avatar-size-jm: 128px;
$avatar-size-2xl: 96px;
$avatar-size-xl: 80px;
$avatar-size-lg: 64px;
$avatar-size-md: 48px;
$avatar-size-sm: 32px;

.avatar {
  align-items: center;
  background-color: $brand-dp8;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.hasImage {
  background-color: $neutral;
}

.sizeSm {
  flex: 0 0 $avatar-size-sm;
  height: $avatar-size-sm;
  width: $avatar-size-sm;
}

.sizeMd {
  flex: 0 0 $avatar-size-md;
  height: $avatar-size-md;
  width: $avatar-size-md;
}

.sizeLg {
  flex: 0 0 $avatar-size-lg;
  height: $avatar-size-lg;
  width: $avatar-size-lg;
}

.sizeXl {
  flex: 0 0 $avatar-size-xl;
  height: $avatar-size-xl;
  width: $avatar-size-xl;
}

.size2x {
  flex: 0 0 $avatar-size-2xl;
  height: $avatar-size-2xl;
  width: $avatar-size-2xl;
}

.sizeJm {
  flex: 0 0 $avatar-size-jm;
  height: $avatar-size-jm;
  width: $avatar-size-jm;
}

.shapeCircle {
  border-radius: 50%;
}

.shapeSquare {
  border-radius: 5%;
}

.contain {
  background-position: 50% 50%;
  background-size: contain;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.initials {
  user-select: none;
}
