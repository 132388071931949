@import "~@serverready-ui/design-language/dist/sass/all";

.selectablePill {
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  input {
    display: none;

    &:disabled {
      & .pill {
        opacity: 0.5;
      }
    }
  }
}
