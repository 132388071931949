@use "sass:math";
@import "@serverready-ui/design-language/dist/sass/all";

.button {
  border: 1px solid;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  line-height: math.div(24, 16);
  justify-content: center;
  outline: 0;
  padding: 10px 24px;
  position: relative;
  transition: border-color 0.2s linear, color 0.2s linear, background-color 0.2s linear;
  font-weight: $font-weight-bold;

  &.grouped {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.sizeXs {
  padding: $ui-spacing-tn $ui-spacing-xs;
  font-size: $font-size-xs;
}

.sizeSm {
  padding: $ui-spacing-xs $ui-spacing-sm;
  font-size: $font-size-sm;
}

.sizeLg {
  padding: $ui-spacing-sm $ui-spacing-md;
  font-size: $font-size-lg;
}

.fullWidth {
  width: 100%;
}

.typeFilled {
  background-color: $brand-light;
  border-color: transparent; // prevents resizing when changing state that changes the border width
  color: $neutral-90;

  &:hover {
    background-color: $black-brand-light-mix-dp3;
  }

  &:focus, &:active {
    background-color: $black-brand-light-mix-dp8;
  }

  &.disabled, &:disabled {
    background-color: rgba($neutral-90, $dp3);
    color: $neutral-45;
  }


  &.statusSuccess {
    background-color: $success;

    &:hover, &:active, &:focus {
      background: $success-tonal;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusError {
    background-color: $error;

    &:hover, &:active, &:focus {
      background: $error-tonal;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusWarning {
    background-color: $warning;

    &:hover, &:active, &:focus {
      background: $warning-tonal;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }
}

// same as typeFilled but with a lighter, same tone color
.typeTonal {
  background-color: $brand-light-300;
  border-color: transparent; // prevents resizing when changing state that changes the border width
  color: $neutral-90;

  &:hover {
    background-color: $black-brand-light-300-mix-dp3;
  }

  &:focus, &:active {
    background-color: $black-brand-light-300-mix-dp8;
  }

  &.disabled, &:disabled {
    background-color: rgba($neutral-90, $dp3);
    color: $neutral-45;
  }

  &.statusSuccess {
    background-color: $success-tonal;

    &:hover, &:active, &:focus {
      background-color: $success-faded;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusError {
    background-color: $error-tonal;

    &:hover, &:active, &:focus {
      background-color: $error-faded;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusWarning {
    background-color: $warning-tonal;

    &:hover, &:active, &:focus {
      background-color: $warning-faded;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }
}

.typeOutline {
  border: 1px solid $brand-light-300;
  background: transparent;
  color: $brand-light-300;

  &:hover {
    background: $brand-light-dp3;
  }

  &:focus {
    background: $brand-light-dp8;
    border-color: $brand-light;
    color: $brand-light;
  }

  &:active {
    background: $brand-light-dp8;
  }

  &.disabled, &:disabled {
    border-color: transparent;
    background-color: rgba($neutral-90, $dp3);
    color: $neutral-45;
  }

  &.statusSuccess {
    border-color: $success;
    color: $success;

    &:hover, &:active, &:focus {
      background: $success;
      color: $neutral-90;
    }

    &.disabled, &:disabled {
      border-color: transparent;
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusError {
    border-color: $error;
    color: $error;

    &:hover, &:active, &:focus {
      color: $neutral-90;
      background: $error;
    }

    &.disabled, &:disabled {
      border-color: transparent;
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusWarning {
    border-color: $warning;
    color: $warning;

    &:hover, &:active, &:focus {
      color: $black;
      background: $warning;
    }

    &.disabled, &:disabled {
      border-color: transparent;
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }
}

.typeText {
  padding: 10px 12px;
  background: none;
  border-color: transparent;
  color: $brand-light;

  &:hover {
    background: $brand-light-dp3;
  }

  &:focus, &:active {
    background: $brand-light-dp8;
  }

  &.disabled, &:disabled {
    background-color: rgba($neutral-90, $dp3);
    color: $neutral-45;
  }

  &.statusSuccess {
    color: $success;

    &:hover, &:active, &:focus {
      background: $success;
      color: $neutral-90;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusError {
    color: $error;

    &:hover, &:active, &:focus {
      background: $error;
      color: $neutral-90;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }

  &.statusWarning {
    color: $warning;

    &:hover, &:active, &:focus {
      background: $warning;
      color: $neutral-90;
    }

    &.disabled, &:disabled {
      background-color: rgba($neutral-90, $dp3);
      color: $neutral-45;
    }
  }
}

.hidden {
  display: none;
}

.disabled {
  box-shadow: none;
  cursor: not-allowed;
  pointer-events: none;
}

.hiddenChildren {
  opacity: 0;
  visibility: hidden;
}

.loadingIndicator {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
