@import "~@serverready-ui/design-language/dist/sass/all";

// if you run into other variables needing to be overridden, add them here
// a list of customisation variables can be found on ag-grid's website:
// https://www.ag-grid.com/react-data-grid/global-style-customisation-variables/
.agThemeOverride {
  --ag-background-color: #{$surface-black-brand-mix-dp84};
  --ag-header-background-color: #{$surface-black-brand-mix-dp94};
  --ag-alpine-active-color: #{$surface-black-brand-mix-dp16};
  --ag-foreground-color: #{$neutral-90};
  --ag-odd-row-background-color: #{$surface-black-brand-mix-dp76};
  --ag-borders-critical: "1px solid #{$neutral-30}";
  --ag-borders: "none";
}
