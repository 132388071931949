@import "~@serverready-ui/design-language/dist/sass/all";
@import "~normalize-scss/sass/normalize/import-now";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$font-size-default: 16px;

html {
  font-size: $font-size-default !important;
}

@font-face {
  font-family: "sofiapro-regular";
  src: url("./fonts/sofia/sofiapro-regular-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro-regularitalic";
  src: url("./fonts/sofia/sofiapro-regularitalic-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro-semibold";
  src: url("./fonts/sofia/sofiapro-semibold-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro-light";
  src: url("./fonts/sofia/sofiapro-light-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro-lightitalic";
  src: url("./fonts/sofia/sofiapro-lightitalic-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro-semibolditalic";
  src: url("./fonts/sofia/sofiapro-semibolditalic-webfont.woff2") format("woff2"),
    url("./fonts/sofia/sofiapro-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
