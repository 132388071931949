@import "@serverready-ui/design-language/dist/sass/all";

.sr-navigation-bar {
  display: block;
  left: 0;
  height: $navigation-bar-height;
  padding: $ui-spacing-sm;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  &:not(.transparent) {
    background-color: $surface-black-brand-mix-dp84;
  }

  &.bordered {
    border-bottom: 1px solid $neutral-90;
  }
}
