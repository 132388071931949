@import "@serverready-ui/design-language/dist/sass/all";

.sr-error-message {
  padding: $ui-spacing-md 0;

  .error-message {
    text-align: center;

    .sr-button {
      display: inline-block;
      margin: $ui-spacing-sm 0;
      width: 50%;
    }
  }
}
