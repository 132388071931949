/*
==================
 VARIABLES.scss

 ## Principles

 * Component variables should be prefixed with the component name (e.g. $navigation-bar-)
 * Variables should be named such that they move from generic -> specific (excepting component prefixes) (e.g. $color-blue-dark)
 * Color variables are first defined by a readable name before assigning a semantic name (HEX strings should never be used to assign a color - this file should act as a directory to ensure consistent colors)
 * 'alt' is a 'reserved word' that specifies a variation (e.g. a button might have a light and dark variant, on being regular and the other being the alt)
 * Typographic variables (font sizes, line heights, etc.) are separate for now since it feels nice to have all typographic stuff lumped together (might make sense to bring them in later)

 ## Recommended Reading

 * http://thesassway.com/beginner/variable-naming
 * https://webdesign.tutsplus.com/articles/quick-tip-name-your-sass-variables-modularly--webdesign-13364

==================
 */

// === fonts ===
$font-regular: "sofiapro-regular", sans-serif;
$font-italic: "sofiapro-regularitalic", sans-serif;
$font-semibold: "sofiapro-semibold", sans-serif;
$font-semibold-italic: "sofiapro-semibolditalic", sans-serif;
$font-light: "sofiapro-light", sans-serif;
$font-light-italic: "sofiapro-lightitalic", sans-serif;

// ==== MISC ====

$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-standard: $border-radius-medium;
$border-radius-large: 16px;
$shadow-large: 0px 8px 16px rgba(0, 0, 0, 0.16), 0px 24px 56px rgba(54, 41, 53, 0.22);
$shadow-medium: 0px 0px 2px rgba(0, 0, 0, 0.19), 0px 7px 25px rgba(54, 41, 53, 0.16);
$shadow-small: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 3px 10px rgba(54, 41, 53, 0.12);
$shadow-tiny: 0px 0px 2px rgba(0, 0, 0, 0.32);
$active-shadow: 0px 0px 0px 3px rgba(0, 160, 170, 0.3);

// ==== SPACING ====

$spacing-tn: 0.5rem;
$spacing-xxs: 0.75rem;
$spacing-xs: 1rem;
$spacing-sm: 2rem;
$spacing-md: 3rem;
$spacing-lg: 5rem;
$spacing-xl: 8rem;
$spacing-xxl: 13rem;

// ==== TYPOGRAPHY ====

$font-weight-light: 300;
$font-weight-regular: 300;
$font-weight-heavy: 600;

// ==== COMPONENTS ====
$navigation-bar-height: 90px;
$sidebar-width: 200px;

// used in conjunction with the `rgba()` function to create a color with opacity (e.g. rgba($brand, $dp01))
// primarily for modifying the background color of elements on hover, focus, and active states
// but also used for cards and other elements that need to be slightly transparent rather than creating a new color
$dp1: 5%;
$dp2: 7%;
$dp3: 8%;
$dp4: 9%;
$dp6: 11%;
$dp8: 12%;
$dp12: 14%;
$dp16: 15%;
$dp24: 16%;
$dpDisabled: 38%;
