.container,
.containerXxl,
.containerXl,
.containerLg,
.containerMd,
.containerSm {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .containerSm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .containerMd, .containerSm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .containerLg, .containerMd, .containerSm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .containerXl, .containerLg, .containerMd, .containerSm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .containerXxl, .containerXl, .containerLg, .containerMd, .containerSm, .container {
    max-width: 1320px;
  }
}

.sizeLg {
  max-width: 1250px;
}

.sizeXl {
  max-width: 1640px;
}

.sizeSm {
  max-width: 860px;
}

.sizeXs {
  max-width: 730px;
}

.sizeTn {
  max-width: 630px;
}
