/*
 These are a bit hacky, but they're also really convenient.
 I'll hopefully get around to defining this better at some point...

 Provides some simple classes that can apply to toggle different elements based on screen size.
 */

// Mobile
@include media-breakpoint-up(xs) {
  .desktop-only,
  .tablet-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

@include media-breakpoint-down(xs) {
  .hide-mobile {
    display: none;
  }
}

// Tablet
@include media-breakpoint-up(md) {
  .mobile-only,
  .desktop-only {
    display: none;
  }

  .tablet-only,
  .hide-mobile {
    display: block;
  }
}

// Desktop
@include media-breakpoint-up(lg) {
  .mobile-only,
  .tablet-only {
    display: none;
  }

  .desktop-only,
  .hide-mobile {
    display: block;
  }
}

.hidden {
  display: none;
}
