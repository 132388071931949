@import "~@serverready-ui/design-language/dist/sass/all";
@import url("https://use.typekit.net/zwd1sby.css");
@import "~normalize-scss/sass/normalize/vertical-rhythm";

// Override the normalize.css variables for font size to ensure consistent styling
$base-font-size: 16px;
$base-line-height: 20px;
$base-font-family: $font-light, sans-serif;
$base-unit: "rem";

$font-size-default: 16px;

html {
  color: $neutral-90;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
}
