@import "~@serverready-ui/design-language/dist/sass/all";

.input {
  input,
  textarea {
    // TODO more styling aligning with material design
    border: 1px solid $neutral-30;
    background-color: $surface-black-dp16;
    border-radius: $border-radius-standard;
    font-weight: $font-weight-regular;
    color: $neutral-90;
    padding: $spacing-xs;
    width: 100%;

    @include placeholder {
      color: $grey-medium-light;
    }

    &:focus {
      @include input-active;
    }

    &:disabled {
      opacity: $dpDisabled;
    }
  }

  textarea {
    @include normalize-font-size(16px);

    line-height: 21px;
    min-height: 100px;
  }
}

.sizeSmall {
  @include normalize-font-size(14px);
  input,
  textarea {
    padding: $spacing-xxs;
  }
}

.sizeLarge {
  @include normalize-font-size(20px);
  input,
  textarea {
    padding: $spacing-sm;
  }
}

.invalid {
  input,
  textarea {
    border-color: $error-dark;

    &:focus {
      border-color: $error-dark;
      box-shadow: 0 0 0 3px rgba($error-dark, 0.2);
    }
  }
}
