@import "@serverready-ui/design-language/dist/sass/all";

.wrapper {
  position: absolute;
}

.promote {
  z-index: z(promote);
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.verticalMiddle {
  top: 50%;
  transform: translateY(-50%);

  &.horizontalMiddle {
    transform: translate(-50%, -50%);
  }
}

.horizontalMiddle {
  left: 50%;
  transform: translateX(-50%);
}

.mobileRelative {
  @include media-breakpoint-down(md) {
    position: relative;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    transform: none !important;
  }
}
