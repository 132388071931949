@import "@serverready-ui/design-language/dist/sass/all";

.card {
  background-color: $surface-black-brand-mix-dp96;
  border-radius: 4px;
  border: 1px solid $surface-black-brand-mix-dp24;
  box-shadow: none;
  position: relative;
}

.noBorder {
  border: 0;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cardHeader {
  background: $surface-black-brand-mix-dp97;
}
