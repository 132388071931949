@import "@serverready-ui/design-language/dist/sass/all";

.page {
  padding-top: 0;

  > section {
    &:first-child {
      margin-top: 0;
    }
  }
}

.hasHeader {
  padding-top: $navigation-bar-height;
}

.hasSidebar {
  padding-left: $sidebar-width;
}

.hasStickyFooter {
  padding-bottom: $navigation-bar-height;
}
