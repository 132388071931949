@mixin background-illustration($image, $size) {
  background: {
    image: url($image);
    position: 95%;
    repeat: no-repeat;
    size: $size;
  }
}

// Automatically generate vendor prefixes for border radius
@mixin border-radius($radius) {
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
  border-radius: $radius;
}

// Automatically generate placeholder styles for cross-browser compatibility
@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin input-active {
  border-color: $brand-light-300;
  box-shadow: 0 0 0 3px rgba($brand-light-300, 0.2);
  cursor: pointer;
  outline: 0;
  transition: 0.3s all ease-in-out;
}

@mixin input-active-reset {
  border-color: $neutral-60;
  box-shadow: unset;
  cursor: auto;
}

// target ie-10 and ie-11
@mixin target-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin link {
  font-family: $font-regular;
  text-decoration: none;
  color: $neutral;

  &:hover {
    color: $neutral-90;
  }

  &.underline {
    text-decoration: underline;
  }

  &.a-xl {
    font-size: $font-size-xl;
  }

  &.a-lg {
    font-size: $font-size-lg;
  }

  &.a-sm {
    font-size: $font-size-sm;
  }

  &.a-xs {
    font-size: $font-size-xs;
  }
}
