// Default sizes that map to the names in `Spacing.tsx`
$ui-spacing-tn: 4px;
$ui-spacing-xs: 8px; // 1
$ui-spacing-sm: 16px; // 2
$ui-spacing-md: 24px; // 3
$ui-spacing-lg: 32px; // 4
$ui-spacing-xl: 48px; // 6
$ui-spacing-2xl: 64px; // 8
$ui-spacing-jm: 80px; // 10

// These sizes are automatically applied on smaller screens to prevent breaking layouts
$responsive-spacing-md: 16px;
$responsive-spacing-lg: 24px;
$responsive-spacing-xl: 32px;
$responsive-spacing-2xl: 48px;
$responsive-spacing-jm: 64px;
