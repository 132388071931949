@import "~@serverready-ui/design-language/dist/sass/all";

:global(.chakra-modal__content-container .chakra-modal__content) {
  background: $surface-black-brand-mix-dp92;
}

:global(.chakra-modal__content-container .chakra-modal__close-btn) {
  top: 24px;
  right: 24px;
}

.modalBody {
  padding-bottom: $ui-spacing-lg !important;
}
