@import "~@serverready-ui/design-language/dist/sass/all";

.label {
  display: block;
  margin-bottom: $ui-spacing-xs;

  + input {
    margin-top: $spacing-xs;
  }
}

.formLabel {
  cursor: pointer;
  color: $neutral-90;
  display: block;
  margin: 0;
}

.formExplainer {
  margin: $ui-spacing-xs 0 0;
}
