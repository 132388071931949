@import "@serverready-ui/design-language/dist/sass/all";

.pill {
  display: inline-flex;
  padding: $ui-spacing-xs;
  text-align: center;
  transition: background 0.2s, color 0.2s;

  svg {
    display: block;
  }
}
// corners
.cornerRound {
  border-radius: 1.25rem;
}

.cornerSquare {
  border-radius: 0.25rem;
}

// size
.fullWidth {
  justify-content: center;
  width: 100%;
}

.sizeTn {
  padding: 3px 7px;
}

.sizeXs {
  padding: 0.1rem $ui-spacing-xs;
}

.sizeSm {
  padding: 6px 12px;
}

.sizeMd,
.sizeH3,
.sizeH2 {
  padding: $ui-spacing-xs $ui-spacing-md;

  &.cornerSquare {
    border-radius: 7px;
  }
}

.colorTransparent {
  background-color: transparent;
  border: 1px solid $neutral;
}

// border
.border {
  border: 1px solid $neutral-90;
}

.clickable {
  cursor: pointer;
}

.noWrap {
  max-width: 100%;
  white-space: nowrap;
}
